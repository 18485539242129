<template>
	<v-card class="mt-3">
		<v-dialog v-model="dialog.status" persistent>
			<CardDialog :dialog="dialog" />
		</v-dialog>
    <v-row class="px-3 px-lg-0 mb-n7" v-if="this.permissao('filial_acompanhamento_metas_vendedores_filtros')">
      <v-col class="d-none d-md-block">
        <v-card-title class="py-1 pl-6">Acompanhamento de metas por vendedor</v-card-title>
      </v-col>
      <v-col cols="6" md="3" class="px-3 px-md-1 mb-n7 mb-md-0">
        <v-autocomplete :items="filiaisFiltro" item-text="filial" item-value="idfilial" label="Filial" outlined
                        dense :disabled="carregando" v-model="busca.idfilial"
                        @change="listar()"></v-autocomplete>
      </v-col>
      <v-col cols="6" md="3" class="px-3 px-md-1 mr-0 mr-md-5">
        <InputDatePicker type="month" :disabled="carregando" v-model="busca.dtmes" label="Data" :outlined="true"
                         :dense="true" />
      </v-col>
    </v-row>
    <v-card-title class="pl-6" v-else>
      {{ $vuetify.breakpoint.smAndDown ? 'Acompanhamento de vendedores' : 'Acompanhamento de metas por vendedor'}}
      <v-spacer/>
      <v-btn :disabled="carregando" color="primary" elevation="0" @click="exportaPlanilha()" class="mt-2 mt-sm-0">
        <v-icon class="pr-0 pr-md-2">mdi-file-excel-outline</v-icon>
        <span class="d-none d-md-block">Exportar em Excel</span>
      </v-btn>
    </v-card-title>
		<v-divider />
		<v-progress-linear indeterminate absolute :active="carregando" />
		<v-card-text class="pt-2 pl-3">
			<p class="text-center text-h6 mt-5" v-if="dados.vendedores.length == 0">Selecione uma filial para listar as
				metas.</p>
			<v-simple-table v-else>
				<thead v-if="dados.mesmetaheader">
					<tr>
						<th>Vendedor</th>
						<th>Meta {{ dados.mesmetaheader[0] }}</th>
						<th>Meta {{ dados.mesmetaheader[1] }}</th>
						<th>Meta {{ dados.mesmetaheader[2] }}</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="dados.vendedores">
						<template v-for="(u, i) in dados.vendedores">
							<tr :key="i">
								<td class="text-truncate" style="max-width: 250px;">
									<v-icon color="red"
										v-if="u.grupo == 63 || u.grupo == 155">mdi-account-alert-outline</v-icon>
									{{ u.vendedor }}
									<br />
									<small>
										<strong>ID:</strong>
										{{ u.idvendedor }}
									</small>
									<small class="ml-3">
										<strong>Admissão:</strong>
										{{ u.dataadmissao }}
									</small>
								</td>
								<td class="text-truncate">
									{{ u.metas[0] | formataDinheiro }}
									<v-chip class="mt-1 font-weight-bold float-right" x-small
										:color="porcentagemMetaRealizada(u.realizadaspercentual[0])">{{
											u.realizadaspercentual[0].toFixed(1) }}%</v-chip>
									<br />
									<small>
										<strong>Realizado:</strong>
										{{ u.realizas[0] | formataDinheiro }}
									</small>
								</td>
								<td class="text-truncate">
									{{ u.metas[1] | formataDinheiro }}
									<v-chip class="mt-1 font-weight-bold float-right" x-small
										:color="porcentagemMetaRealizada(u.realizadaspercentual[1])">{{
											u.realizadaspercentual[1].toFixed(1) }}%</v-chip>
									<br />
									<small>
										<strong>Realizado:</strong>
										{{ u.realizas[1] | formataDinheiro }}
									</small>
								</td>
								<td class="text-truncate">
									{{ u.metas[2] | formataDinheiro }}
									<v-chip class="mt-1 font-weight-bold float-right" x-small
										:color="porcentagemMetaRealizada(u.realizadaspercentual[2])">{{
											u.realizadaspercentual[2].toFixed(1) }}%</v-chip>
									<br />
									<small>
										<strong>Realizado:</strong>
										{{ u.realizas[2] | formataDinheiro }}
									</small>
								</td>
							</tr>
							<tr :key="'v_' + u.idvendedor" class="py-0">
								<td colspan="7" class="text-truncate py-0"
									style="height: 30px !important; border-bottom: 1px solid #000 !important;">
									<small>
										<strong class="mr-2">Observação -</strong>
										<template v-if="u.dataDemissao">
											<strong>Demissão:</strong>
											{{ u.dataDemissao }}
										</template>
										<template v-if="u.dataDemissao && u.observacao">-</template>
										<template v-if="u.observacao">{{ u.observacao }}</template>
									</small>
								</td>
							</tr>
						</template>
					</template>
					<template v-else>
						<tr>
							<td class="text-center" colspan="7">Nenhum registro encontrado.</td>
						</tr>
					</template>
				</tbody>
				<tfoot v-if="dados.vendedores">
					<tr>
						<td class="font-weight-bold">Total</td>
						<td class="font-weight-bold text-truncate">
							{{ dados.total.metas[0] | formataDinheiro }}
							<v-chip class="ml-1 font-weight-bold float-right" x-small
								:color="porcentagemMetaRealizada(dados.total.realizadaspercentual[0])">{{
									dados.total.realizadaspercentual[0].toFixed(2) }}%</v-chip>
							<br />
							<small>
								<strong>Realizado:</strong>
								{{ dados.total.realizas[0] | formataDinheiro }}
							</small>
						</td>
						<td class="font-weight-bold text-truncate">
							{{ dados.total.metas[1] | formataDinheiro }}
							<v-chip class="ml-1 font-weight-bold float-right" x-small
								:color="porcentagemMetaRealizada(dados.total.realizadaspercentual[1])">{{
									dados.total.realizadaspercentual[1].toFixed(2) }}%</v-chip>
							<br />
							<small>
								<strong>Realizado:</strong>
								{{ dados.total.realizas[1] | formataDinheiro }}
							</small>
						</td>
						<td class="font-weight-bold text-truncate">
							{{ dados.total.metas[2] | formataDinheiro }}
							<v-chip class="ml-1 font-weight-bold float-right" x-small
								:color="porcentagemMetaRealizada(dados.total.realizadaspercentual[2])">{{
									dados.total.realizadaspercentual[2].toFixed(2) }}%</v-chip>
							<br />
							<small>
								<strong>Realizado:</strong>
								{{ dados.total.realizas[2] | formataDinheiro }}
							</small>
						</td>
					</tr>
				</tfoot>
			</v-simple-table>
		</v-card-text>
		<v-card-actions class="pa-4" v-if="this.permissao('filial_acompanhamento_metas_vendedores_filtros')">
			<v-spacer />
      <v-btn class="py-5" :disabled="carregando" color="primary" elevation="0" @click="exportaPlanilhaTudo()">
        <v-icon class="pr-0 pr-md-2">mdi-file-excel-outline</v-icon>
        <span class="d-none d-md-block">Exportar Todas Filiais</span>
      </v-btn>
			<v-btn class="py-5" :disabled="carregando" color="primary" elevation="0" @click="exportaPlanilha()">
				<v-icon class="pr-0 pr-md-2">mdi-file-excel-outline</v-icon>
				<span class="d-none d-md-block">Exportar em Excel</span>
			</v-btn>
		</v-card-actions>
		<v-progress-linear indeterminate absolute :active="carregando" />
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import mixinFilial from "../../util/mixinFilial";
import InputDatePicker from "../Widgets/InputDatePicker";
import CardDialog from "../Widgets/CardDialog";

export default {
	name: "FilialDefinirMetas",
	mixins: [mixinFilial],
	components: { InputDatePicker, CardDialog },
	data: () => ({
		dialog: { status: false, title: "", msg: "", icon: "", iconColor: "" },
		dialogConfirmacao: false,
		dialogVendedores: false,
		carregando: false,
		dados: { vendedores: [] },
		vendedoresOcultos: {},
		busca: {
			dtmes: `${new Date().getFullYear()}-${(new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getMonth() +
				1 <
				10
				? "0"
				: "") +
				(new Date().getMonth() + 1)
				}`,
			idfilial: 0,
		},
		metaFilial: "",
		totalMetaVendedor: 0.0,
		dataAtual: `${new Date().getFullYear()}-${(new Date(
			new Date().getFullYear(),
			new Date().getMonth() + 1,
			0
		).getMonth() +
			1 <
			10
			? "0"
			: "") +
			(new Date().getMonth() + 1)
			}-${(new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getDate() < 10
				? "0"
				: "") + new Date().getDate()
			}`,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 4 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		async listar() {
      let novoPeriodo = await this.adicionarMes(this.busca.dtmes);
			if (!this.busca.idfilial) return;
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}comissao/meta/listar`, {
					periodo: novoPeriodo || null,
					idfilial: this.busca.idfilial || null,
				})
				.then((res) => {
					this.dados = res.data;
					this.metaFilial = 0;
					this.totalMetaVendedor = 0;
					if (!this.dados.vendedores) {
						this.dados.vendedores = [];
					}
					this.dados.vendedores.forEach((v) => {
						let dataAdmissao = new Date(
							v.dataadmissao.split("/").reverse().join("-")
						).getTime();
						let dataAtual = new Date(this.dataAtual).getTime();
						let diff = Math.abs(dataAdmissao - dataAtual);
						let days = Math.ceil(diff / (1000 * 60 * 60 * 24));
						let month = days / 30;
						v.meses = month;

						let ano = parseInt(this.busca.dtmes.split("-")[0]);
						let mes = parseInt(this.busca.dtmes.split("-")[1]);

						let ultimoDiaMes = new Date(
							new Date(ano, mes).getFullYear(),
							new Date(ano, mes).getMonth(),
							0
						).getDate();
						let dataUltimoDiaMes = new Date(
							this.busca.dtmes + "-" + ultimoDiaMes + " 00:00:00"
						).getTime();
						let dataPrimeiroDiaMes = new Date(
							this.busca.dtmes + "-01 00:00:00"
						).getTime();

						let diffMounth = Math.abs(
							dataPrimeiroDiaMes - dataUltimoDiaMes
						);
						let daysMounth = Math.ceil(
							diffMounth / (1000 * 60 * 60 * 24) + 1
						);

						if (v.dataInicioFerias && v.dataFimFerias) {
							let dataIniFerias = new Date(
								v.dataInicioFerias
									.split("/")
									.reverse()
									.join("-")
							).getTime();
							let dataFinFerias = new Date(
								v.dataFimFerias.split("/").reverse().join("-")
							).getTime();

							if (
								dataIniFerias >= dataPrimeiroDiaMes &&
								dataIniFerias <= dataUltimoDiaMes &&
								dataFinFerias >= dataPrimeiroDiaMes &&
								dataFinFerias <= dataUltimoDiaMes
							) {
								let diff = Math.abs(
									dataFinFerias - dataIniFerias
								);
								v.diasdeferias =
									Math.ceil(diff / (1000 * 60 * 60 * 24)) + 1;
								v.diastrabalhados = daysMounth - v.diasdeferias;
							} else if (
								dataIniFerias >= dataPrimeiroDiaMes &&
								dataIniFerias <= dataUltimoDiaMes
							) {
								let diff = Math.abs(
									dataUltimoDiaMes - dataIniFerias
								);
								v.diasdeferias = Math.ceil(
									diff / (1000 * 60 * 60 * 24)
								);
								v.diastrabalhados = daysMounth - v.diasdeferias;
							} else if (
								dataFinFerias >= dataPrimeiroDiaMes &&
								dataFinFerias <= dataUltimoDiaMes
							) {
								let diff = Math.abs(
									dataFinFerias - dataPrimeiroDiaMes
								);
								v.diasdeferias =
									Math.ceil(diff / (1000 * 60 * 60 * 24)) + 1;
								v.diastrabalhados = daysMounth - v.diasdeferias;
							} else {
								v.diasdeferias = 0;
								v.diastrabalhados = daysMounth;
							}
						} else {
							v.diasdeferias = 0;
							v.diastrabalhados = daysMounth;
						}

						v.fixar = false;
						if (v.meta) {
							this.metaFilial += v.meta;
							this.totalMetaVendedor += v.meta;
							v.meta = parseFloat(v.meta).toLocaleString(
								"pt-br",
								{ style: "currency", currency: "BRL" }
							);
						} else {
							v.meta = 0;
							v.meta = parseFloat(v.meta).toLocaleString(
								"pt-br",
								{ style: "currency", currency: "BRL" }
							);
						}
						let totalMeta = 0;
						let meses = 0;
						v.metas.forEach((v) => {
							if (v != 0) {
								totalMeta += v;
								meses++;
							}
						});
						if (totalMeta != 0) {
							v.mediaMeta = totalMeta / meses;
						} else {
							v.mediaMeta = 0;
						}
						v.metas.forEach((v) => {
							if (v != 0) {
								totalMeta += v;
								meses++;
							}
						});
					});
					this.metaFilial = parseFloat(
						this.metaFilial
					).toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					});
					this.carregando = false;
				})
				.catch(() => {
					this.dados = {
						vendedores: [{ vendedor: "Welinton " }, {}],
					};
					this.carregando = false;
				});
		},
		async exportaPlanilha() {
      let novoPeriodo = await this.adicionarMes(this.busca.dtmes);
			if (!this.busca.idfilial) return;
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}comissao/meta/planilha/exportar`, {
          periodo: novoPeriodo || null,
					idfilial: this.busca.idfilial || null,
				})
				.then((res) => {
					this.carregando = false;
					const url = window.URL.createObjectURL(
						this.base64ToBlob(res.data)
					);
					const link = document.createElement("a");
					link.href = url;
					link.setAttribute("download", "arquivo vendedores.xlsx");
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(() => {
					this.carregando = false;
				});
		},
    async exportaPlanilhaTudo() {
      let novoPeriodo = await this.adicionarMes(this.busca.dtmes);
      this.carregando = true;
      return axios
          .post(`${this.backendUrl}comissao/meta/planilha/exportar`, {
            periodo: novoPeriodo || null,
            idfilial: 1,
            todas: true,
          })
          .then((res) => {
            this.carregando = false;
            const url = window.URL.createObjectURL(
                this.base64ToBlob(res.data)
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "arquivo vendedores.xlsx");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(() => {
            this.carregando = false;
          });
    },
		base64ToBlob(base64) {
			const decodedData = window.atob(base64);
			const uInt8Array = new Uint8Array(decodedData.length);
			for (let i = 0; i < decodedData.length; ++i) {
				uInt8Array[i] = decodedData.charCodeAt(i);
			}
			return new Blob([uInt8Array], { type: "text/plain" });
		},
		porcentagemMetaRealizada(porcentagem) {
			if (porcentagem < 80) {
				return "red lighten-3";
			} else if (porcentagem >= 80 && porcentagem < 100) {
				return "yellow lighten-2";
			} else {
				return "green lighten-4";
			}
		},
    adicionarMes(periodo) {
      let [ano, mes] = periodo.split("-").map(Number);
      let data = new Date(ano, mes - 1); // Criar data ajustando o mês (0-based)
      data.setMonth(data.getMonth() + 1); // Adicionar 1 mês

      let novoPeriodo = `${data.getFullYear()}-${String(data.getMonth() + 1).padStart(2, "0")}`;
      return novoPeriodo;
    },
	},
	watch: {
		"busca.dtmes": function (newValue, oldValue) {
			if (this.busca.dtmes < "2022-01") {
				this.dialog.status = true;
				this.dialog.title = "Erro";
				this.dialog.msg =
					"A data não pode ser inferior a Janeiro de 2022!";
				this.dialog.icon = "mdi-alert-circle-outline";
				this.dialog.iconColor = "red";
				this.busca.dtmes = oldValue;
			} else {
				this.listar();
			}
		},
	},
	created() {
    this.busca.idfilial = this.usuario.idfilial;
    this.listar();
  },
};
</script>

<style scoped></style>